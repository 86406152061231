import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import {
  SECTION_HOMEPAGE,
  SECTION_CATEGORY,
  getSectionUrl,
  getImageUrl,
  getCategoryUrl,
  createPageUrl,
} from '@wix/communities-blog-client-common';
import { formatTitle, getLastPage } from './pagination';
import { getPaginationPageSize, getEntityCount } from '../selectors/pagination-selectors';
import {
  getFeedSEOTitle,
  getFeedSEOLinks,
  getFeedSEOMetaTags,
} from '../../feed-page/selectors/feed-seo-tags-selectors';
import { getTopology } from '../store/topology/topology-selectors';
import { getTagUrl } from './get-tag-url';
import { getTagListItems } from './get-tag-list-items';

const setPaginationItemData = (originalItemData, state, url, page, t) => {
  const itemData = Object.assign({}, originalItemData);

  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);
  itemData.title = formatTitle({ title: itemData.title, page, lastPage, t });

  if (page > 1) {
    itemData.prevUrl = createPageUrl(page - 1, url || getSectionUrl(state));
  }

  if (page < lastPage) {
    itemData.nextUrl = createPageUrl(page + 1, url || getSectionUrl(state));
  }

  return itemData;
};

export const generateFeedSEOTags = ({ showPagination, state, page = 1, t }) => {
  const { baseUrl, sectionUrl, isHomePage } = getTopology(state);
  const canonicalUrl = page === 1 ? (isHomePage ? baseUrl : sectionUrl) : createPageUrl(page, sectionUrl);
  let title = getFeedSEOTitle(state);
  const links = getFeedSEOLinks(state);
  const metaTags = getFeedSEOMetaTags(state);

  !links.find(item => item.rel === 'canonical') && links.push({ rel: 'canonical', href: canonicalUrl });
  !metaTags.find(item => item.property === 'og:url') && metaTags.push({ property: 'og:url', content: canonicalUrl });

  if (showPagination) {
    const pageSize = getPaginationPageSize(state, SECTION_HOMEPAGE);
    const entityCount = getEntityCount(state, 'posts');
    const lastPage = getLastPage(entityCount, pageSize);
    title = formatTitle({ title, page, lastPage, t });

    if (page > 1) {
      links.push({ rel: 'prev', href: createPageUrl(page - 1, sectionUrl) });
    }

    if (page < lastPage) {
      links.push({ rel: 'next', href: createPageUrl(page + 1, sectionUrl) });
    }
  }

  return {
    title,
    links,
    metaTags,
  };
};

export const generateTagSEOTags = ({ appConfig, tag, showPagination, state, page, posts, t }) => {
  const { postPageSectionUrl, sectionUrl } = appConfig;

  const tagUrl = getTagUrl(sectionUrl, tag.slug);

  let itemData = {
    name: tag.label,
    fullUrl: tagUrl,
    listItems: getTagListItems(posts, postPageSectionUrl),
    created: tag.createdDate,
    postCount: tag.postCount,
  };

  if (showPagination) {
    itemData = setPaginationItemData(itemData, state, tagUrl, page, t);
  }

  return {
    itemType: 'BLOG_TAGS', // constant is not provided by advandes-seo-utils
    itemData: { tag: itemData },
  };
};

export const generateCategorySEOTags = ({ appConfig, category, showPagination, state, page, t }) => {
  const { sectionUrl, categoryPath } = appConfig;
  const categoryUrl = getCategoryUrl(sectionUrl, categoryPath, category.slug);

  let itemData = {
    title: appConfig.useCategoryMenuLabelForMetadataTitle ? category.menuLabel : category.label,
    label: category.menuLabel,
    description: category.description,
    canonicalUrl: createPageUrl(page, categoryUrl || getSectionUrl(state)),
  };

  if (showPagination) {
    itemData = setPaginationItemData(itemData, state, categoryUrl, page, t);
  }

  if (category.cover) {
    const { width, height } = category.cover;
    itemData.image = {
      url: getImageUrl({ imageHost: appConfig.imageHost, image: category.cover }),
      width,
      height,
    };
  }

  return {
    itemType: ITEM_TYPES.BLOG_CATEGORY,
    itemData: { category: itemData },
  };
};
