export const WIX_DUPLEXER_OBJECT = 'wix-duplexer-js';

export default function createDuplexerConnection({ duplexerUrl, blogApiBaseUrl, staticsBaseUrl, instance }) {
  importScripts(staticsBaseUrl + './wix-duplexer.bundle.min.js');
  const Duplexer = global[WIX_DUPLEXER_OBJECT].Duplexer;
  const duplexerClient = new Duplexer(duplexerUrl);
  return duplexerClient.connect({
    apiKey: '8dd942aa-6255-4bb0-b004-8bd6cf2f6b35', // HACK
    auth: {
      url: `${blogApiBaseUrl}/_api/duplexer/auth`,
      onBeforeRequest: () => ({
        headers: {
          instance,
        },
      }),
    },
  });
}
